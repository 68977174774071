import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";

const JSVideoCV = () => {
  const [loading, setLoading] = useState(false);
  const [videoCV, setVideoCV] = useState({
    video: "",
    videoName: "",
  });
  const [video, setVideo] = useState();
  const tokenKey = Cookies.get("tokenClient");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };
  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/addVideoCv",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setVideo(response.data.response.path);
      console.log(video);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get video data");
    }
  };

  const handleVideoRemove = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Video?",
        text: "Do you want to delete this video?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + "/candidates/deleteVideo",
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Video deleted successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        console.log(response);
      }
    } catch (error) {
      Swal.fire({
        title: "Could not delete video. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handleClick = async () => {
    try {
      if (!videoCV.video || videoCV.video === null) {
        Swal.fire({
          title: "Please select a video!",
          icon: "warning",
          confirmButtonText: "Close",
        });
      } else {
        const confirmationResult = await Swal.fire({
          title: "Upload Video",
          text: "Do you want to upload this video?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/candidates/addVideoCv",
            videoCV,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          if (response.data.status === 200) {
            setLoading(false);
            getData();
            Swal.fire({
              title: "Video updated successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            window.scrollTo(0, 0);
          } else {
            setLoading(false);
            Swal.fire({
              title: "Couldn't Update video",
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update video. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not update photo!");
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    // console.log("Selected file name:", fileName);
    const base64 = await convertToBase64(file);
    console.log(base64);
    setVideoCV({ ...videoCV, video: base64, videoName: fileName });
    console.log(videoCV);
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className="container editProfile">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex PageHeader">
                  <img src="/Images/employerSide/icon8color.png" alt="" />
                  <h3 className="">Video CV</h3>
                </div>
                <form>
                  <div className="mb-5 mt-5 mx-4">
                    <div className="form-outline mb-4 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Add a Video File
                        <span className="RedStar">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        lable="Video"
                        name="video"
                        accept=".mp4, .3gp, .avi, .mov"
                        onChange={(e) => handleFileUpload(e)}
                      />{" "}
                      <div id="emailHelp" class="form-text">
                        Supported File Types: mp4, 3gp, avi, mov (Maximum Size
                        20MB).
                      </div>
                    </div>
                    {video ? (
                      <>
                        <div className="form-outline mb-5 DashBoardInputBx">
                          <label htmlFor="formFile" className="form-label">
                            Uploaded Video
                          </label>
                          <div className="ChoosPlanBx checkCertificate">
                            <div class="EPJobseekerCertificatesDetails EPJobseekerVidio">
                              <ul>
                                <li>
                                  <i
                                    class="fa-regular fa-circle-xmark jsprofileCross"
                                    onClick={() => handleVideoRemove()}
                                  ></i>
                                  <i>
                                    <ReactPlayer
                                      url={video}
                                      controls={true}
                                      width={250}
                                      height={250}
                                    />
                                  </i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" mb-5 DashBoardInputBx">
                          <label htmlFor="formFile" className="form-label">
                            Uploaded Video
                          </label>
                          <div className="ChoosPlanBx checkCertificate">
                            <div class="EPJobseekerCertificatesDetails EPJobseekerVidio">
                              {video ? "" : "No video uploaded"}
                              <ul>
                                <li>
                                  <i
                                    class="fa-regular fa-circle-xmark jsprofileCross"
                                    onClick={() => handleVideoRemove()}
                                  ></i>
                                  <i>
                                    <ReactPlayer
                                      url={video}
                                      controls={true}
                                      width={400}
                                      height={300}
                                    />
                                  </i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="bottomButtons">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary button2"
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: "white",
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`,
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSVideoCV;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
