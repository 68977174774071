import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";

const CareerResources = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = "Name is required";
      }
      if (formData.email === "") {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (formData.subject === "") {
        newErrors.subject = "Subject is required";
      }
      if (formData.message === "") {
        newErrors.message = "Message is required";
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              title: "Details submitted. We will contact you shortly!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: "Failed",
        text: "Could not submit your details. Please try again later!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
    console.log(formData);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="privacyPolicy">
            <div className="text-center PPSection1">
              <h1 className="">Career Resources</h1>
              <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  Home
                </Link>{" "}
                /Career Resources
              </h6>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="upperPart">
                    <p>
                      LogicSpice provides a platform to show the skills of the
                      most creative, forward-thinking people of the IT field. We
                      are looking for highly-skilled & talented candidates to
                      join our team. We believe in constant growth of
                      individuals complimented by the collective skills of the
                      company as a whole. We have developed a working
                      environment that fosters innovation, creativity and a
                      hunger for constant upgradation of ones skill sets, along
                      with a healthy dose of fun.
                    </p>

                    <p className="mt-4">
                      We encourage our dedicated employees to achieve their
                      personal career goals. The different points of view they
                      bring, leads to superior business solutions for our
                      clients. We proactively capitalize on business and
                      technology opportunities that enable higher levels of
                      performance.
                    </p>
                    <p className="mt-4">
                      We believe anyone who is part of our team is an asset.
                      Employees like working here because of the great teamwork
                      and the chance to solve challenging problems. We take both
                      your professional and personal development seriously,
                      supporting you in carving out your own individual career
                      path. We are keen in finding newer and bigger
                      opportunities and Projects for our Team along with
                      numerous and comprehensive tools in place to help them.
                    </p>
                    <p className="mt-3">
                      Your goals would be aligned with our overall business
                      goals and values. These goals will guide you through each
                      year and will be documented with your manager who will
                      evaluate and rate your overall performance. This is a key
                      factor in determining any performance bonus and future
                      career opportunities to the employees based on their
                      contribution to the company. We always offered challenging
                      career opportunity to all who wants to work with us.
                    </p>
                    <p className="mt-3">
                      LogicSpice has a unique corporate culture that fosters
                      growth of its people & actively support diversity in the
                      workplace. We are committed to ensure that everyone, our
                      clients, our staff, are valued & treated with respect. We
                      have kept the environment at Office with utmost
                      professionalism and with positive feelings.
                    </p>
                    <p className="mt-3">
                      Logic Spice is a dynamic IT organization, requiring people
                      who have a long-term view of the future. We operate in an
                      environment where every employee is stimulated to perform
                      at their best.
                    </p>
                    <p className="mt-3">
                      Work-life balance, non-working weekends, no stretched
                      working hours and an friendly work environment are few of
                      the many perks of working with us. We offer competitive
                      salaries, excellent benefits and inspired thinking.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="text-center pb-4 pt-2">
                        Send us a
                        <span className="textGradient">
                          {" "}
                          <span className="SubHaddingTxt"> Message</span>
                        </span>
                      </h2>
                      <form>
                        <div className="mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="name"
                            value={formData.name}
                            placeholder="Name*"
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="email"
                            value={formData.email}
                            placeholder="Email*"
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <select
                            className={`form-select ${
                              errors.subject && "input-error"
                            }`}
                            aria-label="Default select example"
                            value={formData.subject}
                            name="subject"
                            onChange={handleChange}
                          >
                            <option>Please Select</option>
                            <option value="1">General Query</option>
                            <option value="2">Personal Feedback</option>
                            <option value="3">Pre Sale Question</option>
                            <option value="3">Support Issue</option>
                            <option value="3">Refund Issue</option>
                          </select>
                          {errors.subject && (
                            <div className="text-danger">{errors.subject}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <textarea
                            className={`form-control ${
                              errors.message && "input-error"
                            }`}
                            id="exampleFormControlTextarea1"
                            rows="5"
                            name="message"
                            value={formData.message}
                            placeholder="Message*"
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && (
                            <div className="text-danger">{errors.message}</div>
                          )}
                        </div>
                        <div className="reCaptchaLogin mb-4">
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            // sitekey="6Ld8bV8nAAAAAEp24xWlKsVFhVDYlBctFF50MI1x"
                            onChange={(value) => setIsCaptchaVerified(value)}
                          />
                          {errors.captcha && (
                            <div className="text-danger">{errors.captcha}</div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn w-100"
                          onClick={handleClick}
                          style={{
                            backgroundColor: `${
                              secondaryColor &&
                              (hoverColor ? secondaryColor : primaryColor)
                            }`,
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          SEND MESSAGE
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default CareerResources;
