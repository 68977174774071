import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const JSChangePhoto = () => {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState({
    profile_image: "",
  });

  const [picture, setPicture] = useState("");
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/uploadPhoto",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setPhoto(response.data.response.profile_image);
      setPicture(response.data.response.profile_image);
      console.log(photo);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get profile photo data");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleClick = async () => {
    try {
      if (!photo.profile_image || photo.profile_image === null) {
        Swal.fire({
          title: "Please select an image!",
          icon: "warning",
          confirmButtonText: "Close",
        });
      } else {
        const confirmationResult = await Swal.fire({
          title: "Upload Logo",
          text: "Do you want to upload this logo?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/candidates/uploadPhoto",
            photo,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setLoading(false);
          getData();
          Swal.fire({
            title: "Logo updated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update logo. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not update photo!");
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setPhoto({ ...photo, profile_image: base64 });
  };

  return (
    <>
      <NavBar />
      <div className="container changeLogo">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex mx-3 PageHeader">
                  <img src="/Images/employerSide/icon10color.png" alt="" />
                  <h3 className="mx-2">Change Profile Picture</h3>
                </div>
                <div className="JSChangePhoto mb-4 mt-5 mx-3">
                  {picture ? (
                    <img src={picture} alt="profile" />
                  ) : (
                    <img src="/Images/jobseekerSide/dummy-profile.png" alt="" />
                  )}
                </div>
                <form>
                  <div className="mb-4 mt-5 mx-3">
                    <div class="mb-5 DashBoardInputBx">
                      <label for="formFile" class="form-label">
                        New Image<span className="RedStar">*</span>
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        lable="Image"
                        name="profile_image"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <div id="emailHelp" class="form-text">
                        Supported File Types: gif, jpg, jpeg, png (Max. 10MB).
                        Min file size 250 X 250 pixels.
                      </div>
                    </div>
                  </div>
                  <div className="buttonsSec">
                    <button
                      type="button"
                      className="btn btn-primary button1 mx-3"
                      onClick={handleClick}
                      style={{
                        backgroundColor: hoverFirstButtonColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverFirstButtonColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleFirstButtonMouseEnter}
                      onMouseLeave={handleFirstButtonMouseLeave}
                    >
                      UPLOAD
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary button2"
                      style={{
                        color: hoverSecondButtonColor
                          ? primaryColor
                          : secondaryColor,
                        backgroundColor: "white",
                        border: hoverSecondButtonColor
                          ? `2px solid ${primaryColor}`
                          : `2px solid ${secondaryColor}`,
                      }}
                      onMouseEnter={handleSecondButtonMouseEnter}
                      onMouseLeave={handleSecondButtonMouseLeave}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSChangePhoto;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
