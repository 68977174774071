import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import { Link, useParams } from "react-router-dom";
import NavBar from "../element/NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const FavouriteListProfile = () => {
  const [loading, setLoading] = useState(false);
  const [favouriteListProfileData, setFavouriteListProfileData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userEducation, setUserEducation] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [mailReply, setMailReply] = useState({
    subject: "",
    message: "",

    emailFiles: [],
  });
  const [errors, setErrors] = useState({
    subject: "",
    message: "",
  });
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [viewButtonEmailData, setViewButtonEmailData] = useState([]);
  const [viewButtonContactData, setViewButtonContactData] = useState([]);

  const [viewEmail, setViewEmail] = useState(false);
  const [viewContact, setViewContact] = useState(false);

  const { slug } = useParams();
  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/candidates/profile/${slug}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setFavouriteListProfileData(response.data.response);
      setOldImages(response.data.response.showOldImages);
      setUserData(response.data.response.userdetails);
      setUserEducation(response.data.response);
      console.log(favouriteListProfileData);
    } catch (error) {
      setLoading(false);
      console.log("Could not get user data in profile page of favourite list");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMailReply((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleFavourie = async (id) => {
    try {
      // setLoading(true)
      const response = await axios.post(
        BaseApi + `/candidates/addtoFavorite/${id}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Added to favourite successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        getData();
      } else {
        Swal.fire({
          title: "Failed",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not add to favourite. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handleReply = async (slug) => {
    try {
      const newErrors = {};

      if (mailReply.subject === "") {
        newErrors.subject = "Subject is required";
        window.scrollTo(0, 0);
      }
      if (mailReply.message === "") {
        newErrors.message = "Message is required";
        window.scrollTo(0, 0);
      }
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Send E-Mail",
          text: "Do you want to send this email?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          const updatedReply = {
            ...mailReply,
            selectedFileName: selectedFileName,
            id: userData.id,
          };

          const formData = new FormData();
          selectedFileName.forEach((fileName, index) => {
            formData.append(`selectedFileNames[${index}]`, fileName);
          });

          // setLoading(true)
          const response = await axios.post(
            BaseApi + `/candidates/sendmailjobseeker/${slug}`,
            updatedReply,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          if (response.data.status === 200) {
            Swal.fire({
              title: "You have sent the email to the candidate successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setMailReply({
              ...mailReply,
              subject: "",
              message: "",
              emailFiles: "",
            });
            navigate(`/candidates/profile/${userData.slug}`);
          } else {
            Swal.fire({
              title: "Failed",
              text: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not reply to this mail. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handleEmailViewClick = async () => {
    try {
      setViewEmail(true);
      const response = await axios.post(
        BaseApi + `/candidates/getUserdetail/${slug}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setViewButtonEmailData(response.data.response);
      console.log(response.data.response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleContactViewClick = async () => {
    try {
      setViewContact(true);
      const response = await axios.post(
        BaseApi + `/candidates/getUserdetail/${slug}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setViewButtonContactData(response.data.response);
      console.log(response.data.response);
    } catch (error) {
      console.log(error);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="container FavouriteListProfile editProfile">
            {/* Reply Modal  */}
            <div
              class="modal fade"
              id="ReplyModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Send mail to {userData.first_name} {userData.last_name}
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="form-outline mb-5 mt-4 DashBoardInputBx">
                      <label class="form-label" for="form3Example3">
                        Subject <span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.subject && "input-error"
                        }`}
                        placeholder="Subject"
                        value={mailReply.subject}
                        name="subject"
                        onChange={handleChange}
                      />
                      {errors.subject && (
                        <div className="text-danger">{errors.subject}</div>
                      )}
                    </div>
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label class="form-label" for="form3Example3">
                        Message <span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.message && "input-error"
                        }`}
                        placeholder="Message"
                        value={mailReply.message}
                        name="message"
                        onChange={handleChange}
                      />
                      {errors.message && (
                        <div className="text-danger">{errors.message}</div>
                      )}
                    </div>
                    <div class="form-outline mb-3 DashBoardInputBx">
                      <label for="formFile" class="form-label">
                        Multiple Images
                      </label>
                      <input
                        type="file"
                        id="formFile"
                        className="form-control"
                        name="file"
                        multiple
                        onChange={(e) => {
                          const files = Array.from(e.target.files);

                          // Capture the selected file names
                          const fileNames = files.map((file) => file.name);
                          setSelectedFileName(fileNames);

                          // Convert each selected file to base64 encoding
                          Promise.all(
                            files.map((file) => convertFileToBase64(file))
                          )
                            .then((base64Array) => {
                              setMailReply({
                                ...mailReply,
                                emailFiles: base64Array,
                              });
                            })
                            .catch((error) => {
                              console.error(
                                "Error converting files to base64:",
                                error
                              );
                            });
                        }}
                      />{" "}
                      <div id="emailHelp" class="form-text mt-2">
                        Select multiple file with Ctrl press, Supported File
                        Types: gif, jpg, jpeg, png, pdf, doc, docx (Max 5 images
                        and Max. 10MB).
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary button1"
                      onClick={() => handleReply(userData.slug)}
                      style={{
                        backgroundColor: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleSearchMouseEnter}
                      onMouseLeave={handleSearchMouseLeave}
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* View modal */}
            <div
              class="modal fade"
              id="ViewModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    You don't have the membership plan active to view the
                    jobseeker contact details. Please purchase that respective
                    membership plan
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2 FLPLeftSideHeader">
                <div className="ImageSpace">
                  {userData.profile_image ? (
                    <img
                      className="FLPImage"
                      src={userData.profile_image}
                      alt="Profile Picture"
                    />
                  ) : (
                    <img
                      className="FLPImage"
                      src="/Images/jobseekerSide/dummy-profile.png"
                      alt="Profile Picture"
                    />
                  )}
                </div>
              </div>
              <div
                className="col-lg-10 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="card FLPHeaderCard">
                  <div className="FLPRightSideHeader">
                    <h4
                      className=""
                      style={{
                        color: secondaryColor,
                      }}
                    >
                      {userData.first_name} {userData.last_name}
                    </h4>
                    <div className="FLPInnerRight">
                      <Link
                        id="FLLink"
                        className="btn FLPlink"
                        data-bs-toggle="modal"
                        data-bs-target="#ReplyModal"
                        style={{
                          color: secondaryColor,
                        }}
                      >
                        Send Mail
                      </Link>
                      {favouriteListProfileData.fav_status === 0 ? (
                        <Link
                          className="btn FLPlink"
                          onClick={() => handleFavourie(userData.id)}
                          style={{
                            color: secondaryColor,
                          }}
                        >
                          Add to Favourite
                        </Link>
                      ) : (
                        <Link
                          className="btn FLPlink"
                          style={{
                            color: secondaryColor,
                          }}
                        >
                          <i class="fa-solid fa-star"></i> Favourite
                        </Link>
                      )}

                      <Link
                        to=""
                        id="FLLink"
                        className="btn FLPlink"
                        onClick={() => window.history.back()}
                        style={{
                          color: secondaryColor,
                        }}
                      >
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="FLPRightSideBody">
                    <div className="row">
                      <div className="col-md-2">
                        <h6>Email Address: </h6>
                      </div>
                      <div className="col-md-10 emailValueFLP">
                        {viewEmail ? (
                          <>
                            <p className="pt-1">{viewButtonEmailData.email}</p>
                          </>
                        ) : (
                          <>
                            {userData.email_address?.includes("@xxxx") ? (
                              <>
                                <p className="pt-1">
                                  {userData.email_address}{" "}
                                  <Link
                                    className="viewLink"
                                    type=""
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#ViewModal"
                                    onClick={handleEmailViewClick}
                                  >
                                    Show
                                  </Link>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="pt-1">{userData.email_address}</p>
                              </>
                            )}{" "}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <h6>Contact Number: </h6>
                      </div>
                      <div className="col-md-10 contactValueFLP">
                        {viewContact ? (
                          <>
                            <p className="pt-1">
                              {viewButtonContactData.contact}
                            </p>
                          </>
                        ) : (
                          <>
                            {userData.contact?.includes("+xxxx") ? (
                              <>
                                <p className="pt-1">
                                  {userData.contact}{" "}
                                  <Link
                                    className="viewLink"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#ViewModal"
                                    onClick={handleContactViewClick}
                                  >
                                    Show
                                  </Link>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="pt-1">{userData.contact}</p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {userData.location && (
                      <div className="row">
                        <div className="col-md-2 listProfileParent">
                          <h6>Native Location: </h6>
                        </div>
                        <div className="col-md-10 listProfileChild">
                          {userData.location}
                        </div>
                      </div>
                    )}
                    {userData.total_exp && (
                      <div className="row">
                        <div className="col-md-2 listProfileParent">
                          <h6>Work Experience: </h6>
                        </div>
                        <div className="col-md-10 listProfileChild">
                          {userData.total_exp}
                        </div>
                      </div>
                    )}
                    {userData.skills && (
                      <div className="row">
                        <div className="col-md-2 listProfileParent">
                          <h6>Skills: </h6>
                        </div>
                        <div className="col-md-10 listProfileChild">
                          {Object.entries(userData.skills).map(([key, val]) => {
                            return val + ", ";
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {userData.education && (
              <div className="row">
                <div className="tableHeader">
                  <h4
                    style={{
                      color: secondaryColor,
                    }}
                  >
                    Education:
                  </h4>
                </div>
                {userData.education != "" ? (
                  userData.education?.map((i, index) => {
                    return (
                      <>
                        <div className="dashboardSection3 FLPEducationTable">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Qualification</th>
                                <th>Course Name</th>
                                <th>Specialization</th>
                                <th>University/Institute</th>
                                <th>Passed</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{i.course_name ? i.course_name : "N/A"}</td>
                                <td>{i.position}</td>
                                <td>
                                  {i.basic_university
                                    ? i.basic_university
                                    : "N/A"}
                                </td>
                                <td>{i.basic_year ? i.basic_year : "N/A"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="FLPNAText">Not Available</div>
                )}
              </div>
            )}

            {userData.experience && (
              <div className="row">
                <div className="tableHeader">
                  <h4
                    style={{
                      color: secondaryColor,
                    }}
                  >
                    Experience:
                  </h4>
                </div>
                {userData.experience != "" ? (
                  userData.experience?.map((i, index) => {
                    return (
                      <>
                        <div className="dashboardSection3 FLPEducationTable">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Experience</th>
                                <th>Company Name</th>
                                <th>Industry</th>
                                <th>Functional Area</th>
                                <th>Role</th>
                                <th>Designation</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{i.course_name ? i.course_name : "N/A"}</td>
                                <td>{i.position}</td>
                                <td>
                                  {i.basic_university
                                    ? i.basic_university
                                    : "N/A"}
                                </td>
                                <td>{i.basic_year ? i.basic_year : "N/A"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="FLPNAText">Not Available</div>
                )}
              </div>
            )}

            <div className="row">
              <div className="FLPAboutCandidateHeader">
                <h4
                  style={{
                    color: secondaryColor,
                  }}
                >
                  About Candidate:
                </h4>
              </div>
              <div className="FLPAboutCandidateBody">
                {userData.company_about ? (
                  HTMLReactParser(userData.company_about)
                ) : (
                  <div className="FLPNAText">Not Available</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="FLPCandidateCertificateHeader">
                <h4
                  style={{
                    color: secondaryColor,
                  }}
                >
                  CV Document/Certificates:
                </h4>
              </div>
              <div className="FLPCandidateCertificateBody">
                {oldImages &&
                  oldImages?.map((i) => {
                    return (
                      <img
                        className="FLPCandidateCertificateImage"
                        src={i.document}
                        alt="document"
                      />
                    );
                  })}
                {oldImages.length === 0 && (
                  <div className="FLPCandidateCertificateBody">
                    <div className="FLPNAText">Not Available</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default FavouriteListProfile;
